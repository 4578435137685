<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

//COMPONENTS
import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";
import Modal from "@/components/Modal.vue";
import ScrollButton from "@/components/ScrollButton.vue";
import SimpleLoading from "@/components/SimpleLoading.vue";

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		SecondaryButton,
		Modal,
		ScrollButton,
		SimpleLoading,
	},
	data() {
		return {
			simpleLoading: false,
			listed: false,
			isScrolling: false,
			totalPages: null,
			currentPage: null,
			query: "",
			profile: {},
			user: {},
			culture: {},
			votes: null,
			showCultures: false,
			modal: {
				user: null,
				culture: null,
				voted: false,
				show: false,
			},
			users: [],
			cultures: [],
			loadingMoreData: false,
			comment: "",
		};
	},
	created() {
		this.listed = false;
		this.isScrolling = false;
		this.currentPage = 1;
		this.query = "";
		if (!this.isTour) {
			this.loadVotesPending();
			this.loadProfile();
		}
		this.loadCultures();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",

			//READ_PROFILE
			readProfile: "auth/readUser",

			//READ_USERS
			readUsers: "getUsers/readUsers",
			readVotesCount: "getVotesCount/readVotes",
			readVotesPending: "getVotesPending/readVotes",
			readSearchUser: "searchUser/readUsers",
			readTotalPages: "getUsers/readTotalPages",
			readSearchTotalPages: "searchUser/readTotalPages",

			//READ_CULTURES
			readCultures: "getCultures/readCultures",

			//READ_ERRORS
			getUsersError: "getUsers/readError",
			getCulturesError: "getCultures/readError",
			createVoteError: "createVote/readError",
			getVotesCountError: "getVotesCount/readError",
			getVotesPendingError: "getVotesPending/readError",
			getProfileError: "auth/readError",
			searchUserError: "searchUser/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",

			//SEARCH_USER_FILTER
			setSearchFilter: "searchUser/SET_FILTER",
			setUsersSearchPagination: "searchUser/SET_PAGINATION",

			//SET_USER_ID
			setUserId: "getUsers/SET_ID",
			setVotesCountId: "getVotesCount/SET_ID",
			setVotesPendingId: "getVotesPending/SET_ID",

			//SET_VOTE
			setVote: "createVote/SET_VOTE",

			//SET_FILTERS_ID
			setUsersFilter: "getUsers/SET_FILTER",
			setUsersPagination: "getUsers/SET_PAGINATION",

			//SET_ERRORS
			setProfileError: "auth/SET_ERROR",
			setUsersError: "getUsers/SET_ERROR",
			setCulturesError: "getCultures/SET_ERROR",
			setVoteError: "createVote/SET_ERROR",
			setVotesCountError: "getVotesCount/SET_ERROR",
			setVotesPendingError: "getVotesPending/SET_ERROR",
			setSearchError: "searchUser/SET_ERROR",
		}),
		...mapActions({
			//SEARCH_USER
			searchUser: "searchUser/getUsers",

			//USERS
			getUsers: "getUsers/getUsers",
			getVotesCount: "getVotesCount/getVotesCount",
			getVotesPending: "getVotesPending/getVotesPending",

			//CULTURES
			getCultures: "getCultures/getCultures",

			//VOTE
			createVote: "createVote/createVote",
		}),
		orderedArray(arr) {
			return arr.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
		},
		loadProfile() {
			this.clearErrors();
			this.profile = this.readProfile;
			this.loadUsers();
		},
		loadSearch: _.debounce(function (scroll) {
			if (this.query.length > 0) {
				if (!scroll) {
					this.currentPage = 1;
					this.$nextTick(() => {
						this.$refs.usersList?.scrollTo(0, 0);
					});
					this.simpleLoading = true;
				} else {
					this.loadingMoreData = true;
				}
				this.clearErrors();
				this.setSearchFilter(this.query);
				this.setUsersSearchPagination({
					page: this.currentPage,
					size: "18",
				});
				this.searchUser().then((result) => {
					if (result && this.readSearchUser.length > 0) {
						this.totalPages = Math.ceil(
							this.readSearchTotalPages / 18
						);
						this.filterUsers(this.readSearchUser);
					} else {
						this.simpleLoading = false;
						this.loadingMoreData = false;
					}
				});
			} else {
				this.loadUsers();
			}
		}, 200),
		loadUsers() {
			if (this.currentPage > 1) {
				this.loadingMoreData = true;
			} else {
				this.setLoading(true);
			}
			this.clearErrors();
			this.setUserId(null);
			this.setUsersPagination({
				page: this.currentPage,
				size: "18",
			});
			this.setUsersFilter(null);
			this.getUsers().then((result) => {
				if (result && this.readUsers.length > 0) {
					this.totalPages = Math.ceil(this.readTotalPages / 18);
					this.filterUsers(this.readUsers);
				} else {
					this.setLoading(false);
					this.loadingMoreData = false;
				}
			});
		},
		async filterUsers(payload) {
			payload.filter((u, key) => {
				const pageKey = [(this.currentPage - 1) * 18 + key];
				if (this.currentPage == 1) {
					this.users = payload;
					this.pageKey = key;
				} else {
					this.$set(this.users, pageKey, u);
				}
			});
			this.listed = true;
			this.simpleLoading = false;
			this.isScrolling = false;
			this.loadingMoreData = false;
			this.setLoading(false);
		},
		loadCultures() {
			this.clearErrors();
			this.getCultures().then((result) => {
				if (result) {
					this.cultures = this.readCultures;
				}
			});
		},
		loadVotesPending() {
			this.clearErrors();
			this.getVotesPending().then((result) => {
				if (result) {
					this.votes = this.readVotesPending.pending;
				}
			});
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		selectUser(user) {
			if (
				!this.isTour ||
				(this.isTour && user?.id == "7777777777777777")
			) {
				if (user) {
					if (!user.voted) {
						if (!this.user.id) {
							this.user = user;
							this.showCultures = true;
						} else {
							this.user = {};
							this.culture = {};
							this.comment = "";
							this.showCultures = false;
						}
						this.$nextTick(() => {
							return this.$refs.usersList?.scrollTo(0, 0);
						});
					} else {
						this.modal.user = null;
						this.modal.culture = null;
						this.modal.voted = true;
						this.toggleModal();
					}
				} else {
					this.user = {};
					this.culture = {};
					this.comment = "";
					this.showCultures = false;
				}
			}
		},
		selectCulture(culture) {
			if (culture) {
				if (
					!this.isTour ||
					(this.isTour &&
						culture?.id == this.cultures[1].id &&
						!this.culture.id)
				) {
					!this.culture.id || this.culture.id != culture?.id
						? (this.culture = culture)
						: (this.culture = {});
				}
			} else {
				this.culture = {};
			}
		},
		toggleModal() {
			this.modal.show = !this.modal.show;
		},
		fakeVote() {
			this.$set(this.users[0], "voted", true);
		},
		submitVote() {
			if (!this.isTour) {
				this.simpleLoading = true;
				this.clearErrors();
				let vote = {
					id: null,
					receiver_id: this.user.id,
					culture_element_id: this.culture.id,
					comment: this.comment,
				};
				this.setVote(vote);
				this.createVote()
					.then((result) => {
						if (result) {
							this.modal.user = this.user;
							this.modal.culture = this.culture;
							this.modal.voted = false;
							this.toggleModal();
							this.loadVotesPending();
						}
					})
					.catch()
					.finally(() => {
						this.simpleLoading = false;
						this.user = {};
						this.culture = {};
						this.comment = "";
						this.showCultures = false;
						this.loadSearch();
					});
			}
		},
		loadPage() {
			if (this.currentPage == this.totalPages) {
				return;
			} else {
				this.currentPage++;
				this.loadSearch(true);
			}
		},
		scroll() {
			if (
				!this.showCultures &&
				!this.isScrolling &&
				this.totalPages > 1
			) {
				const usersList = this.$refs.usersList;

				if (
					usersList?.scrollTop >
					(usersList?.scrollHeight - usersList?.offsetHeight) * 0.9
				) {
					this.isScrolling = true;
					this.loadPage();
				}
			}
		},
		scrollDown() {
			const scrollHeight = this.$refs.usersList?.scrollHeight;
			this.$refs.usersList?.scrollTo(0, scrollHeight);
		},
		clearErrors() {
			this.setProfileError(null);
			this.setUsersError(null);
			this.setCulturesError(null);
			this.setVoteError(null);
			this.setVotesCountError(null);
			this.setVotesPendingError(null);
			this.setSearchError(null);
		},
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<Modal
			@click.native="toggleModal"
			v-if="modal.show"
			buttonText="Cerrar"
			buttonColor="gradient-button-gray"
		>
			<p v-if="!modal.voted" class="text-black text-lg text-center">
				Acabas de
				<span class="b elektra-red">reconocer</span> a
				<span class="b">{{ modal.user.name }}, </span> por vivir el
				Principio
				<span class="b">{{ modal.culture.name }}.</span>
			</p>
			<p class="text-black text-lg" v-else>
				Parece que este colaborador te está
				<span class="b">Creando</span> muchos<span class="b">
					Momentos
				</span>
				<span class="b">Felices</span>. Regresa la próxima semana para
				otorgarle un nuevo reconocimiento.
			</p>
		</Modal>
		<Modal v-if="showCultures && !isLoading && culture.id">
			<div class="py-2 px-5 flex flex-col items-center relative">
				<font-awesome-icon
					@click="selectCulture(null)"
					icon="fa-solid fa-xmark"
					class="absolute right-0 top-0 text-black rounded-full border border-white w-5 h-5"
				/>
				<span class="text-black text-lg b text-center"
					>¿Deseas agregar algún comentario?</span
				>
				<span class="text-black">(opcional)</span>
				<textarea
					id="commentReconocer"
					class="w-full flex-grow text-lg black h-56 mt-2 pl-4 pr-8 py-2 text-lg rounded-md bg-white"
					placeholder="Comentarios adicionales"
					v-model="comment"
					maxlength="140"
				/>
				<span class="text-black mt-2 mb-4"
					><span class="elektra-red">{{ 140 - comment.length }} </span
					>caracteres disponibles</span
				>
				<Secondary-button
					id="buttonReconocer"
					@click.native="submitVote"
					class="py-2 ml-3 min-w-min"
					text="Reconocer"
					loadingColor="text-white"
					loadingFontSize="2xl"
					:isLoading="simpleLoading"
				></Secondary-button>
			</div>
		</Modal>
		<div class="my-2 flex flex-col overlay bg-white mx-auto">
			<div
				class="px-5 pt-8 bg-contain bg-top bg-no-repeat mt-1"
				
			>
				<div class="bg-overlay">
					<p
						v-if="!showCultures"
						class="text-center text-lg leading-6 mb-2"
					>
						Busca a todos los
						<span class="b elektra-red">colaboradores</span>
						<span class="block">
							que quieras reconocer y selecciónalos.</span
						>
					</p>
					<p v-else class="text-center text-lg leading-6 mb-2">
						¿Por qué quieres reconocer a este
						<span class="b elektra-red">compañero</span>?
					</p>
				</div>
			</div>
			<div class="mx-5 relative flex justify-center">
				<input
					:disabled="isTour"
					id="search"
					v-if="!showCultures"
					@keyup="loadSearch()"
					class="search w-full flex-grow mb-2 text-lg black h-12 mt-2 pl-4 pr-8 py-2 text-lg rounded-md bg-white"
					type="text"
					placeholder="Apellido Nombre(s)"
					v-model="query"
				/>
				<font-awesome-icon
					v-if="!query && !showCultures"
					class="absolute right-3 bottom-1/2 transform translate-y-1/2 text-xl red"
					icon="fa-solid fa-magnifying-glass"
				/>
				<simple-loading
					v-if="simpleLoading && !showCultures && query"
					fontSize="text-2xl"
					class="absolute right-3 bottom-1/2 transform translate-y-1/2 text-xl red"
				></simple-loading>
			</div>
			<div
				@scroll="scroll"
				id="usersList"
				v-if="!isLoading && users.length > 0"
				class="px-5 users-list overflow-y-scroll pt-3"
				:class="{
					'h-4/6 flex-1':
						users.length != 1 && users[0].id != profile.id,
				}"
				ref="usersList"
			>
				<div
					v-for="(u, key) in users"
					:key="u.id"
					@click.stop="selectUser(u)"
					class="user bg-gray cursor-pointer flex justify-between items-center p-2 rounded-xl overflow-visible"
					:class="
						u.voted
							? 'item item--voted mb-3'
							: user.id == u.id
							? 'item item--clicked mb-5'
							: 'item mb-3'
					"
					v-show="
						(u.id != profile.id ||
							(u.id == profile.id && isTour)) &&
						(!user.id || user.id == u.id)
					"
					:id="`user_${key}`"
				>
					<span
						class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
					>
						<img
							
							class="block w-full h-full object-cover object-center flex-none transform "
							:src="u.photo || 'profile.png'"
							alt="Trofeo"
						/>
					</span>
					<div class="w-3/6 pl-2 flex-grow">
						<span
							v-capitalizeWords="
								!u.name || u.basic_info
									? `${u.first_name} ${u.last_name}`
									: u.name
							"
							class="block text-md truncate"
						></span>
						<span
							v-capitalizeWords="u.work_position"
							class="block text-sm truncate"
						></span>
					</div>
					<img
						v-if="!u.voted"
						class="icon_vote flex-none"
						:src="getImage('voto.png')"
						alt="Corazón B y N"
					/>
					<img
						v-else
						class="icon_vote flex-none"
						:src="getImage('voto_h.png')"
						alt="Corazón Color"
					/>
				</div>
				<div
					id="culturesList"
					class="cultures-list"
					v-if="showCultures && user.id"
					ref="culturesList"
				>
					<scroll-button
						@click.native="scrollDown"
						class="fixed right-0 bottom-1/2 z-10"
					></scroll-button>
					<div
						:id="`culture_${key}`"
						v-show="c.active"
						v-for="(c, key) in cultures"
						:key="c.id"
						@mouseup="selectCulture(c)"
						class="px-4 bg-gray-dark text-white flex justify-center p-2 rounded-xl mb-3 cursor-pointer"
						:class="
							culture.id == c.id
								? 'culture culture--clicked'
								: 'culture'
						"
					>
						<span class="text-sm text-center">
							{{ c.answer }}
						</span>
					</div>
				</div>

				<simple-loading
					v-if="loadingMoreData && !this.showCultures"
				></simple-loading>
			</div>
			<div
				v-else-if="listed"
				class="px-5 text-md m-auto font-md b blue text-center"
			>
				No se encontraron Colaboradores
			</div>
			<div
				v-if="users.length == 1 && users[0].id == profile.id"
				class="px-5 text-md m-auto font-md b blue text-center"
			>
				Si deseas ver tus
				<span class="green">reconocimientos</span> puedes ir a la
				sección de <span class="green">Notificaciones</span>
			</div>
			<div class="mt-auto">
				<nav-bar-votacion
					:active="this.$route.name"
					:go-back="showCultures && !isLoading"
					@goBack="selectUser(null)"
				></nav-bar-votacion>
				<Footer class="static"></Footer>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.overlay {
	height: calc(100% - 1rem);
}
.user,
.culture {
	border: get-vh(8px) solid #d6e2f0;
}

.item {
	box-shadow: 0px 5px 6px rgba($color: #224c57, $alpha: 0.1);

	&--voted {
		background: linear-gradient(to bottom, #d9291b, #5e0903 200%);
		color: #fff;

		.icon_user {
			box-shadow: none;
		}
	}
	&--clicked {
		background: none;
		box-shadow: none;
		color: #fff;
		border: none;
		background: linear-gradient(to top, #d9291b, #5e0903 200%);

		.icon_user {
			color: $orange;
			background: $gray-light;
		}

		.icon_vote {
			margin-top: -7%;
			margin-right: 4%;
			transform: scale(1.6);
		}
	}
}

.culture {
	box-shadow: 4px 3px 5px rgba($color: #000, $alpha: 0.25);
	&--clicked {
		background: $yellow;
		color: $black;
	}
}

.icon_user,
.icon_vote {
	transition: all 0.3s ease;
}

.icon_vote {
	height: get-vh(130px);
	width: get-vh(130px);
	min-width: get-vh(130px);
	display: block;
	margin-right: get-vh(-6px);
}
.icon_user {
	box-sizing: content-box;
	// border: get-vh(6px) solid #d6e2f0;
	width: get-vh(120px);
	height: get-vh(120px);
	box-shadow: 0 0 0 get-vh(6px) rgba($color: #d6e2f0, $alpha: 1),
		0px 0px 5px 2px rgba($color: #d6e2f0, $alpha: 0.8);
}

.search {
	border: 1px solid $red;
	outline-color: $red !important;
	transition: all 0.3s;
}
</style>
